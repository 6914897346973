import React, { useEffect } from "react";
import PromptPlaceholder from "./UI/PromptPlaceholder";

import Thumb from "../images/Success.svg";
import { exitApp, parseQueryString } from "../util";
import { useTranslation } from "react-i18next";

function Success() {
	const { t } = useTranslation();
	const val = parseQueryString(window.location.search);
	const { courseName } = val;

	// useEffect(() => {
	//   setTimeout(() => {
	//     exitApp();
	//   }, 2500);
	// }, []);

	return (
		<PromptPlaceholder
			className="Success"
			heading={t("src.components.success.congratulations", "Congratulations!")}
			icon={Thumb}
		>
			{t(
				"src.components.success.purchasedPlan",
				"You have successfully purchased our plan"
			)}
			<br />
			{t("src.components.success.platformNow", "You can use our platform now.")}
		</PromptPlaceholder>
	);
}

export default Success;
