import React, { useEffect, useRef } from "react";
import PromptPlaceholder from "./UI/PromptPlaceholder";

import Thumb from "../images/Pending.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Pending({ val, loopCounter, checkStatus }) {
	const history = useHistory();
	const { t } = useTranslation();
	const timer = useRef(null);

	useEffect(() => {
		timer.current = setTimeout(() => {
			console.log("Hello, World!");
			checkStatus(history.push);
		}, 3000);

		return () => {
			clearTimeout(timer.current);
		};
	}, [loopCounter]);

	return (
		<PromptPlaceholder
			heading={t(
				"src.components.pending.paymentProcessing",
				"Payment Processing"
			)}
			icon={Thumb}
			className="Pending"
		>
			{t(
				"src.components.pending.underProcessingText",
				"Your payment is currently under processing. Please wait for sometime."
			)}
		</PromptPlaceholder>
	);
}

export default Pending;
