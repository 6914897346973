import React, { useEffect } from "react";
import PromptPlaceholder from "./UI/PromptPlaceholder";

import Thumb from "../images/Loading.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Loading({ checkStatus }) {
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		checkStatus(history.push);
	}, []);

	return (
		<PromptPlaceholder
			heading="Loading Payment"
			icon={Thumb}
			className="Loading"
		>
			{t(
				"src.components.loading.waitText",
				"Please wait while we redirect you to your payment page"
			)}
		</PromptPlaceholder>
	);
}

export default Loading;
