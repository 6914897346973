import axios from "axios";
import apiConfig from "./apiConfig";

const paymentsApiCall = async (
	{ token, orderId, escrowId, message, region },
	onSuccess
) => {
	try {
		const apiRes = await axios.post(
			apiConfig.url(),
			apiConfig.data({orderId, escrowId, }),
			apiConfig.config({token, region: region ? region : "IN" })
		);

		onSuccess(apiRes?.data?.data.status);
	} catch (err) {
		onSuccess("failure");

		console.log(err);
	}

	// TODO ONSUCCESSEVENT
};

export default paymentsApiCall;
