//const baseUrl = "https://wl-gcp-staging.classplus.co";
const baseUrl = "https://api.classplusapp.com";
const urlPrefix = "v3/diy/pay/tzp/escrowStatus";

const apiConfig = {
  url: () => `${baseUrl}/${urlPrefix}`,
  config: ({ token, region }) => {
    return {
      headers: {
        "x-access-token": token,
        region,
      },
    };
  },
  data: ({ orderId, escrowId }) => {
    return {
      diyOrderId: orderId,
      escrowId: escrowId,
    };
  },
};

export default apiConfig;
