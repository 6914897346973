import React from 'react';
import CTA from './CTA';
import './styles.scss';

function PromptPlaceholder({
  icon,
  heading,
  children,
  button,
  onClick,
  className,
}) {
  return (
    <div
      className={
        className ? `PromtPlaceholder ${className}` : 'PromtPlaceholder'
      }
    >
      <img className="PromtPlaceholder__Thumb" src={icon} alt="paymentIcon" />
      <div className="PromtPlaceholder__Heading">{heading}</div>
      <div className="PromtPlaceholder__Body">{children}</div>

      {button ? (
        <div className="PromtPlaceholder__Action">
          <CTA label={button} onClick={onClick} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default PromptPlaceholder;
