import PromptPlaceholder from "./UI/PromptPlaceholder";
import { exitApp } from "../util";

import Thumb from "../images/Failure.svg";
import { useTranslation } from "react-i18next";

function Failure() {
	const { t } = useTranslation();
	return (
		<PromptPlaceholder
			heading={t("src.components.failure.paymentFailed", "Payment failed!")}
			icon={Thumb}
			className="Failure"
			button={`${t("src.components.failure.retryPayments", "Retry Payments")}`}
			onClick={exitApp}
		>
			{t(
				"src.components.failure.failureText",
				"Your payment for this subscription could not be completed. Please retry again. Don’t worry in case money has been deducted from your account. They are safe."
			)}
		</PromptPlaceholder>
	);
}

export default Failure;
