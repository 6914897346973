import React from 'react';

import './styles.scss';

function CTA({ onClick, label }) {
  return (
    <div className="CTA" onClick={onClick}>
      {label}
    </div>
  );
}

export default CTA;
